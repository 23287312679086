import React, { useEffect, useState } from "react"
import styled from "styled-components"
import * as queryString from "querystring"
import { Link, navigate } from "gatsby"
import firebase from "firebase/app"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import { useAuthHandlerContext } from "../../auth/components/AuthHandler/AuthHandler"
import MediumPlusHeading from "../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import MaxWidthContent, {
  desktopMaxWidthContentCss,
} from "../../ui/components/MaxWidthContent/MaxWidthContent"
import theme from "../../ui/theme"
import {
  clearSigningInStorage,
  getDestinationParam,
  getIsSigningInViaApple,
  getIsSigningInViaFb,
  storeSigningInViaApple,
  storeSigningInViaFb,
  StyledAppleButton,
  StyledDivider as SignUpStyledDivider,
  StyledFacebookButton,
  StyledOptionWrapper,
} from "../SignUpScreen/views/CreateStep/views/SelectSignUpOptionView/SelectSignUpOptionView"
import { ROUTES } from "../../navigation/routes"
import SignInForm from "./components/SignInForm/SignInForm"
import {
  firebaseAppleSignInWithPopup,
  firebaseAppleSignInWithRedirect,
  firebaseFacebookSignInWithPopup,
  firebaseFacebookSignInWithRedirect,
  shouldSignInWithPopup,
} from "../../firebase/auth"
import { minHeightCss } from "../SignUpScreen/views/InfoStep/InfoStep"
import { DESKTOP_BREAKPOINT, MOBILE_BREAKPOINT } from "../../ui/responsive"
import AppleIcon from "../../ui/icons/AppleIcon/AppleIcon"
import {
  fancyCondensedFontCss,
  fancyWideFontCss,
} from "../../ui/typography/fonts"
import { ButtonLoadingIndicator } from "../../ui/buttons/ActionButton/ActionButton"
import { mixpanelEventSignIn } from "../../analytics/mixpanel"
import { gaEventAccountSignIn } from "../../analytics/google"
import FirebaseErrorMessage from "../SignUpScreen/views/CreateStep/views/SelectSignUpOptionView/components/FirebaseErrorMessage/FirebaseErrorMessage"
import { isFirebasePrdEnv } from "../../utils/env"

export const StyledDesktopWrapper = styled.div`
  ${DESKTOP_BREAKPOINT} {
    ${desktopMaxWidthContentCss};
    position: relative;
  }
`

export const StyledWrapper = styled(MaxWidthContent)`
  color: ${theme.colors.white};
  ${minHeightCss};

  ${DESKTOP_BREAKPOINT} {
    padding-top: 78px;
  }
`

const StyledDivider = styled(SignUpStyledDivider)`
  margin: ${theme.spacing.small}px 0;
`

const StyledHeading = styled(MediumPlusHeading)`
  line-height: ${theme.fontSizes.large}px;
  margin-top: 4.5px;
  margin-bottom: ${theme.spacing.small}px;

  ${MOBILE_BREAKPOINT} {
    span:last-child {
      display: none;
    }
  }

  ${DESKTOP_BREAKPOINT} {
    ${fancyCondensedFontCss};
    position: absolute;
    top: 78px;
    left: 10px;
    text-transform: uppercase;
    margin-top: -20px;
    font-size: 44px;

    span {
      ${fancyWideFontCss};
      color: ${theme.colors.primary};
      font-size: 46px;
      margin-left: 8px;
    }
  }
`

const StyledSignUpWrapper = styled.div`
  margin-top: 17.5px;
  line-height: 27px;
  color: ${theme.colors.lightGrey};
  text-align: center;

  a {
    color: ${theme.colors.white};
    text-decoration: underline;
    margin-left: 4px;
    display: inline-block;
  }
`

const SignInScreen: React.FC = () => {
  const mixpanel = useMixpanel()
  const { authenticated, setAuthenticated } = useAuthHandlerContext()
  const [destination, setDestination] = useState(getDestinationParam())
  const [signingInViaFb, setSigningInViaFb] = useState(false)
  const [fbError, setFbError] = useState<Error>()
  const [appleError, setAppleError] = useState<Error>()
  const [signingInViaApple, setSigningInViaApple] = useState(false)

  // have to trigger this on client to ensure page is updated
  useEffect(() => {
    setSigningInViaFb(getIsSigningInViaFb())
    setSigningInViaApple(getIsSigningInViaApple())
  }, [])

  const handleSignIn = () => {
    setAuthenticated(true)
  }

  useEffect(() => {
    // set twice to cover both server and client
    setDestination(getDestinationParam())
  }, [])

  useEffect(() => {
    if (signingInViaFb || signingInViaApple) return
    if (authenticated) {
      // console.log("redirecting?")
      navigate(destination, {
        replace: true,
      })
    }
  }, [authenticated, signingInViaFb, signingInViaApple])

  const handleFacebookAuth = () => {
    setSigningInViaFb(true)

    firebaseFacebookSignInWithPopup()
      .then(result => {
        mixpanelEventSignIn(mixpanel, "Facebook")
        gaEventAccountSignIn("Facebook")
        if (
          result &&
          result.additionalUserInfo &&
          result.additionalUserInfo.isNewUser
        ) {
          navigate(ROUTES.signUpDetails.path)
        } else {
          setSigningInViaFb(false)
        }
      })
      .catch(error => {
        console.error(error)
        Sentry.captureMessage(
          "Something went wrong when signing in via Facebook"
        )
        Sentry.captureException(error)
        setFbError(error)
        setSigningInViaFb(false)
      })
      .finally(() => {
        clearSigningInStorage()
      })

    // storeSigningInViaFb()
    // setTimeout(() => {
    //   firebaseFacebookSignInWithRedirect()
    // }) // timeout ensures local storage is set before redirect
  }

  const handleAppleAuth = () => {
    setSigningInViaApple(true)
    const signInWithPopup = shouldSignInWithPopup()
    if (signInWithPopup) {
      firebaseAppleSignInWithPopup()
        .then(result => {
          mixpanelEventSignIn(mixpanel, "Apple")
          gaEventAccountSignIn("Apple")
          if (
            result &&
            result.additionalUserInfo &&
            result.additionalUserInfo.isNewUser
          ) {
            navigate(ROUTES.signUpDetails.path)
          } else {
            setSigningInViaApple(false)
          }
        })
        .catch(error => {
          console.error(error)
          Sentry.captureMessage(
            "Something went wrong when signing in via Apple"
          )
          Sentry.captureException(error)
          setAppleError(error)
          setSigningInViaApple(false)
        })
        .finally(() => {
          clearSigningInStorage()
        })
    } else {
      storeSigningInViaApple()
      setTimeout(() => {
        firebaseAppleSignInWithRedirect()
      }) // timeout ensures local storage is set before redirect
    }
  }

  useEffect(() => {
    const fbSignIn = getIsSigningInViaFb()
    firebase
      .auth()
      .getRedirectResult()
      .then(result => {
        if (result && result.user) {
          if (signingInViaFb) {
            mixpanelEventSignIn(mixpanel, "Facebook")
            gaEventAccountSignIn("Facebook")
          } else if (signingInViaApple) {
            mixpanelEventSignIn(mixpanel, "Apple")
            gaEventAccountSignIn("Apple")
          }
        }
        if (
          result &&
          result.additionalUserInfo &&
          result.additionalUserInfo.isNewUser
        ) {
          navigate(ROUTES.signUpDetails.path)
        } else {
          setSigningInViaFb(false)
          setSigningInViaApple(false)
        }
      })
      .catch(error => {
        // // Handle Errors here.
        // const errorCode = error.code
        // const errorMessage = error.message
        // // The email of the user's account used.
        // const { email } = error
        // // The firebase.auth.AuthCredential type that was used.
        // const { credential } = error
        console.error(error)
        Sentry.captureMessage(
          "Something went wrong when getting firebase auth redirect result"
        )
        Sentry.captureException(error)
        try {
          const { credential } = error
          if (fbSignIn || credential.signInMethod === "facebook.com") {
            setFbError(error)
          } else {
            setAppleError(error)
          }
        } catch (tryError) {
          console.error(tryError)
        }
        setSigningInViaFb(false)
        setSigningInViaApple(false)
      })
      .finally(() => {
        clearSigningInStorage()
      })
  }, [])

  return (
    <StyledDesktopWrapper>
      <StyledWrapper>
        <StyledHeading>
          Sign in<span>.</span>
        </StyledHeading>
        <section>
          <StyledOptionWrapper>
            <StyledFacebookButton
              onClick={handleFacebookAuth}
              loading={signingInViaFb}
            >
              {fbError
                ? "Facebook sign in failed! Try again"
                : "Sign in with Facebook"}
              {signingInViaFb && <ButtonLoadingIndicator />}
            </StyledFacebookButton>
            {fbError && <FirebaseErrorMessage error={fbError} />}
          </StyledOptionWrapper>
          <StyledOptionWrapper>
            <StyledAppleButton
              onClick={handleAppleAuth}
              loading={signingInViaApple}
            >
              <AppleIcon />
              {appleError
                ? "Apple sign in failed! Try again"
                : "Sign in with Apple"}
              {signingInViaApple && <ButtonLoadingIndicator />}
            </StyledAppleButton>
            {appleError && <FirebaseErrorMessage error={appleError} />}
          </StyledOptionWrapper>
        </section>
        <StyledDivider>or</StyledDivider>
        <SignInForm onSignIn={handleSignIn} />
        <StyledSignUpWrapper>
          Don’t have an account?{" "}
          <Link to={ROUTES.signUp.paramPath(destination)}>Sign up</Link>
        </StyledSignUpWrapper>
      </StyledWrapper>
    </StyledDesktopWrapper>
  )
}

export default SignInScreen
