import React from "react"
import { graphql } from "gatsby"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import SignInScreen from "../screens/SignInScreen/SignInScreen"
import PageLocation from "../analytics/components/PageLocation/PageLocation"
import { ROUTES } from "../navigation/routes"
import PageMetaTags, {
  CMSWebMetaSocial,
} from "../components/SiteMeta/PageMetaTags/PageMetaTags"

interface Props {
  data: CMSWebMetaSocial
}

const SignInPage: React.FC<Props> = props => (
  <SiteWrapper darkTheme>
    <SiteLayout>
      <PageLocation pageName={ROUTES.signIn.name}>
        <PageMetaTags data={props} />
        <SignInScreen />
      </PageLocation>
    </SiteLayout>
  </SiteWrapper>
)

export const query = graphql`
  query SignInScreenQuery(
    $devEnv: Boolean = false
    $stgEnv: Boolean = false
    $uatEnv: Boolean = false
    $prdEnv: Boolean = false
  ) {
    datoCmsWebMetaSocialDev @include(if: $devEnv) {
      screenMetaTags: signInScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialStg @include(if: $stgEnv) {
      screenMetaTags: signInScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocial @include(if: $uatEnv) {
      screenMetaTags: signInScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialPrd @include(if: $prdEnv) {
      screenMetaTags: signInScreenMetaTags {
        ...PageMeta
      }
    }
  }
`

export default SignInPage
