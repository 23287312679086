import React, { useState } from "react"
import styled from "styled-components"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import { StyledOptionWrapper } from "../../../SignUpScreen/views/CreateStep/views/SelectSignUpOptionView/SelectSignUpOptionView"
import ActionButton, {
  ButtonLoadingIndicator,
} from "../../../../ui/buttons/ActionButton/ActionButton"
import theme from "../../../../ui/theme"
import { firebaseSignInWithEmailAndPassword } from "../../../../firebase/auth"
import { mixpanelEventSignIn } from "../../../../analytics/mixpanel"
import { gaEventAccountSignIn } from "../../../../analytics/google"
import ForgotDetailsModal, {
  StyledInput,
} from "../../../../components/ForgotDetailsModal/ForgotDetailsModal"

const StyledForgotWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const StyledForgot = styled.button`
  font-size: ${theme.fontSizes.smallish}px;
  line-height: ${theme.lineHeights.smallish}px;
  text-align: center;
  color: inherit;
`

const SignInForm: React.FC<{
  onSignIn: () => void
}> = () => {
  const mixpanel = useMixpanel()
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [showForgotModal, setShowForgotModal] = useState(false)
  const [busy, setBusy] = useState(false)
  const [error, setError] = useState<any>()
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    if (busy) return
    setBusy(true)
    setError(undefined)
    firebaseSignInWithEmailAndPassword(username, password)
      .then(response => {
        // console.log("response", response)
        mixpanelEventSignIn(mixpanel, "Email")
        gaEventAccountSignIn("Email")
      })
      .catch(fbError => {
        console.error(fbError)
        Sentry.captureMessage(
          "Something went wrong when signing in with email and password"
        )
        Sentry.captureException(fbError)
        setError(fbError)
      })
      .finally(() => {
        setBusy(false)
      })
  }
  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <StyledOptionWrapper>
          <StyledInput
            value={username}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUsername(event.target.value)
            }}
            type="email"
            placeholder="Email"
          />
        </StyledOptionWrapper>
        <StyledOptionWrapper>
          <StyledInput
            value={password}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value)
            }}
            type="password"
            placeholder="Password"
          />
        </StyledOptionWrapper>
        <StyledOptionWrapper>
          <ActionButton type="submit" loading={busy}>
            {error
              ? "Unable to sign in. Check your details and try again."
              : "Sign in"}
            {busy && <ButtonLoadingIndicator />}
          </ActionButton>
        </StyledOptionWrapper>
        <StyledOptionWrapper>
          <StyledForgotWrapper>
            <StyledForgot
              onClick={() => setShowForgotModal(true)}
              type="button"
            >
              Forgot your password?
            </StyledForgot>
          </StyledForgotWrapper>
        </StyledOptionWrapper>
      </form>
      {showForgotModal && (
        <ForgotDetailsModal
          initialEmail={username}
          onClose={() => {
            setShowForgotModal(false)
          }}
        />
      )}
    </>
  )
}

export default SignInForm
